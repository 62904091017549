import React, { useState, useEffect } from 'react'
import TimeColumn from './TimeColumn'
import { MeridiemColumn } from './TimeColumn'
import { createUseStyles } from 'react-jss'

// type TimeitProps = {
//   onChange?: (value: string) => any;
//   defaultValue?: string;
//   minuteExclude?: Array<number>;
//   hourExclude?: Array<number>;
//   notShowExclude?: boolean;
// };

const useStyles = createUseStyles(
	{
		wrapper: {
			'display': 'flex',
			'direction': 'ltr',
			'justifyContent': 'center',
			'&>*': {
				margin: '0 5px',
			},
			'&>:last-child': {
				marginRight: '0',
			},
			'&>:first-child': {
				marginLeft: '0',
			},
		},
	},
	{
		name: 'timeit',
	}
)

const Timeit = ({ isSameOrAfter, onChange, defaultValue, useMeridiem, minuteExclude, hourExclude, notShowExclude }) => {
	//: TimeitProps
	const classes = useStyles()
	const [hour, setHour] = useState('00')
	const [minute, setMinute] = useState('00')
	const [meridiem, setMeridiem] = useState('AM')
	const currentHour = new Date().getHours()
	const currentMins = new Date().getMinutes()

	const determineMeridiem = (hrs, min) => {
		let m = 'AM'
		if (min.includes(' ')) {
			m = min.indexOf('PM') != -1 ? 'PM' : 'AM'
		} else {
			m = parseInt(hrs) >= 12 ? 'PM' : 'AM'
		}
		return m
	}

	useEffect(() => {
		setHour(
			defaultValue && typeof defaultValue === 'string'
				? parseInt(defaultValue?.split(':')[0]) > 12
					? (parseInt(defaultValue?.split(':')[0]) - 12).toString()
					: defaultValue?.split(':')[0]
				: '00'
		)
		setMinute(defaultValue && typeof defaultValue === 'string' ? defaultValue?.split(':')[1] : '00')
		setMeridiem(
			defaultValue && typeof defaultValue === 'string'
				? determineMeridiem(defaultValue?.split(':')[0], defaultValue?.split(':')[1])
				: 'AM'
		)
	}, [defaultValue])

	useEffect(() => {
		onChange && onChange(`${hour}:${minute} ${meridiem}`)
	}, [hour, minute, meridiem])

	return (
		<div className={classes.wrapper}>
			<TimeColumn
				notShowExclude={notShowExclude}
				start={isSameOrAfter ? +-(12 - currentHour) : 1}
				end={12}
				value={hour}
				setValue={setHour}
				exclude={hourExclude}
			/>
			<TimeColumn
				notShowExclude={notShowExclude}
				start={isSameOrAfter && parseInt(hour) == +-(12 - currentHour) ? currentMins + 1 : 0}
				end={59}
				value={minute}
				setValue={setMinute}
				exclude={minuteExclude}
			/>
			<MeridiemColumn
				notShowExclude={notShowExclude}
				start={isSameOrAfter && currentHour > 12 ? 'PM' : 'AM'}
				end="PM"
				value={meridiem}
				setValue={setMeridiem}
				exclude={minuteExclude}
			/>
		</div>
	)
}

export default Timeit
