import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
	Box,
	Modal,
	Icon,
	VStack,
	Button,
	Heading,
	Image,
	Text,
	HStack,
	ModalHeader,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
} from '@chakra-ui/react'

import Calendar from 'react-calendar'

import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)

import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import Timeit from '../TimeSelector'

const ModalCalendar = ({ saveLabel, value, onChange, onClose, isOpen, useTime, allowPast, hideCurrent }) => {
	const formatDate = (val) => dayjs(val).format(useTime ? 'ddd, MMM D YYYY, h:mm a' : 'ddd MMM D, YYYY')

	const [tabIndex, setTabIndex] = React.useState(0)
	/** Save copy of the value received as the initial value */
	const [initialValue, setInitialValue] = useState(value)
	/** Format value that was received */
	const [initialFormattedValue, setInitialFormattedValue] = useState(formatDate(value))

	/** Prepare new date selection, using the initial value as default */
	const [newDate, setNewDate] = useState(value || formatDate(dayjs()))
	/** Format new date selection */
	const [newFormattedDate, setNewFormattedDate] = useState(formatDate(newDate))
	/** Calendar is set to today's date */
	const [isSameOrAfter, setIsSameOrAfter] = useState(dayjs().isSameOrAfter(value, 'day'))
	/** Get Value as Datetime */
	const valueDate = dayjs(value).toDate()
	/** Get Now Datetime add a minute so we don't get caught in a loop */
	const now = dayjs().add(2, 'minute').toDate()
	/** Set Calendar props */
	const [calendarProps, setCalendarProps] = useState({})
	useEffect(() => {
		if (!allowPast) setCalendarProps({ minDate: now })
	}, [allowPast])
	/** Set the DefaultTime if we are dealing with today's date */
	const [defaultTimeValue, setDefaltTimeValue] = useState(`${now.getHours()}:${now.getMinutes()} PM`)

	/** Update initial value when date changes */
	useEffect(() => {
		if (value != initialValue) {
			setInitialValue(value)
			setInitialFormattedValue(formatDate(value))
			setNewDate(isSameOrAfter ? now : value)
			setNewFormattedDate(isSameOrAfter ? formatDate(now) : formatDate(value))
			setDefaltTimeValue(
				isSameOrAfter
					? `${now.getHours()}:${now.getMinutes()} ${now.getHours() > 12 ? 'PM' : 'AM'}`
					: `${valueDate.getHours()}:${valueDate.getMinutes()} ${
							valueDate.getHours() > 12 || isSameOrAfter ? 'PM' : 'AM'
					  }`
			)
		}
	}, [value])

	const handleChange = (val) => {
		const past = dayjs().isSameOrAfter(val, 'day')
		const changeDate = val

		if (useTime) {
			/** First, convert to dayjs so we can manipulate the date */
			const d = dayjs(changeDate)
			let new_date
			/** If we have an initial value, retrieve the existing time from there */
			const h = dayjs(past ? dayjs() : initialValue).hour()
			const m = dayjs(past ? dayjs().add(2, 'minute') : initialValue).minute()
			new_date = d.hour(h).minute(m)
			setNewDate(new_date.toDate())
			setNewFormattedDate(formatDate(new_date))
			setDefaltTimeValue(`${h}:${m} ${h > 12 ? 'PM' : 'AM'}`)
		} else {
			setNewDate(changeDate)
			setNewFormattedDate(formatDate(changeDate))
		}

		if (past) {
			setIsSameOrAfter(true)
		} else {
			setIsSameOrAfter(false)
		}
	}

	/**
	 * Callback handler to update the time
	 * @param {*} val
	 */
	const handleTimeChange = (val) => {
		const t = val?.split(' ')[0] // 12:54 AM => 12:54
		const h = t?.split(':')[0] // 12:54 => 12
		const m = t?.split(':')[1] // 12:54 => 54
		const meridiem = val.indexOf('PM') != -1 ? 'PM' : 'AM'
		const d = dayjs(newDate)
			.hour(meridiem == 'PM' ? parseInt(h) + 12 : parseInt(h))
			.minute(parseInt(m))
		const past = dayjs().isSameOrAfter(d)
		setNewDate(past ? now : d.toDate())
		setNewFormattedDate(dayjs(past ? now : d).format('ddd, MMM D YYYY, h:mm a'))
	}

	return (
		<Modal onClose={onClose} isOpen={isOpen} scrollBehavior="outside" isCentered={true} size="sm">
			<ModalOverlay />
			<ModalContent>
				<ModalCloseButton />
				<ModalBody>
					<VStack textAlign="center" spacing="1rem">
						<Heading size="lg" variant="hero">
							Pick a date
						</Heading>
						{!hideCurrent && initialValue && (
							<HStack
								p="0.5rem"
								spacing="1rem"
								w="full"
								backgroundColor="gray.100"
								borderRadius="md"
								fontSize="0.875rem"
								color="#6C7A88">
								<Text>Current Date:</Text>
								<Text fontWeight="bold">{initialFormattedValue}</Text>
							</HStack>
						)}

						{initialValue != newDate && (
							<HStack
								p="0.5rem"
								spacing="1rem"
								w="full"
								borderRadius="md"
								borderColor="gray.100"
								borderWidth="2px"
								fontSize="0.875rem">
								<Text>Selected date:</Text>
								<Text fontWeight="bold">{newFormattedDate}</Text>
							</HStack>
						)}

						<Tabs variant="enclosed" index={tabIndex} onChange={setTabIndex} w="full">
							<TabList>
								<Tab>Date</Tab>
								{useTime && <Tab>Time</Tab>}
							</TabList>
							<TabPanels>
								<TabPanel px="0">
									<Calendar
										onChange={handleChange}
										value={dayjs(newDate).toDate()}
										{...calendarProps}
									/>
								</TabPanel>
								{useTime && (
									<TabPanel mx="auto">
										<Timeit
											defaultValue={defaultTimeValue}
											onChange={(val) => handleTimeChange(val)}
											isSameOrAfter={isSameOrAfter}
										/>
									</TabPanel>
								)}
							</TabPanels>
						</Tabs>
					</VStack>
				</ModalBody>
				<ModalFooter>
					<HStack w="full" spacing="1rem" justifyContent="center">
						<Button
							variant="ghost"
							onClick={() => {
								setNewDate(initialValue)
								onClose()
							}}>
							Cancel
						</Button>
						{useTime && tabIndex == 0 && (
							<Button
								onClick={() => {
									setTabIndex(1)
								}}>
								Select a time
							</Button>
						)}
						{((useTime && tabIndex == 1) || !useTime) && (
							<Button
								onClick={() => {
									setInitialValue(newDate)
									setInitialFormattedValue(formatDate(newDate))
									onChange(newDate)
									onClose()
								}}>
								{saveLabel ? saveLabel : 'Save'}
							</Button>
						)}
					</HStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

export default ModalCalendar
